import http from '../utils/http.js'

const Note = {

	/**	单张图片上传方法
	 * @param {Object} formData	formdata对象
	 */
	async imageUploadByOne(formData) {
		return new Promise((resolve, reject) => {
			http.post("/api/images/uploadImageOne", formData).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	查询指定tid, uid的笔记
	 * @param {Object} data	tid以及uid的对象
	 */
	async getMessageByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/message/getMessageByTid", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	查询指定id的笔记--新业务
	 * @param {Object} data	id
	 */
	async selectNoteById(data) {
		return new Promise((resolve, reject) => {
			http.get("/api/note/all/selectNoteTextById?id="+data.id).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	当前用户所有一级未删除的标题
	 * @param {Object}  uid当前登录用户的uid
	 */
	async getNoteTitleByAloneOnUid() {
		return new Promise((resolve, reject) => {
			http.post("/api/note/getNoteTitleByAloneOnUid", {}).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	
	/**	修改指定id, uid的笔记
	 * @param {Object} data	笔记对象
	 */
	async updateNoteByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/note/updateNote", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	删除指定id, uid的笔记
	 * @param {Object} data	笔记对象
	 */
	async deleteNoteByTid(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/note/deleteNote", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	
	/**	新增笔记标题
	 * @param {Object} data	笔记对象
	 */
	async insertNoteTitle(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/note/insertNoteTitle", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**	查询笔记标签
	 * @param {Object}
	 */
	async getNoteTag(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/note/all/noteTagList").then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	/**	查询笔记摘要数据
	 * @param {Object} data	笔记对象
	 */
	async selectNoteAbstracts(data) {
		return new Promise((resolve, reject) => {
			http.post("/api/note/all/selectNoteAbstracts", data).then(resp => {
				resolve(resp.data);
			}).catch(err => {
				reject(err);
			});
		});
	},

}

export default Note;
